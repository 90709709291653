.swift-transaction-management .strategy-content {
  position: relative;
}

.trasaction-header {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.trasaction-header .trasaction-title {
  font-size: 18px;
  color: var(--font-primary);
  font-weight: 700;
}

.admin-wrapper {
  height: 85%;
  padding: 0 0px;
}

.admin-wrapper.full {
  height: 90%;
}

.admin-wrapper .admin-menu {
  display: flex;
  padding: 0 0px;
}

.admin-wrapper .admin-menu .admin-menu-option {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 30px;
  cursor: pointer;
  border: 1px solid var(--white);
  padding: 8px 20px;
  border-radius: 3px;
  transition: 0.3s;
}

.admin-wrapper .admin-history-container {
  border-radius: 15px;
  width: 100%;
  padding: 0px 0px 5px;
  overflow-y: scroll;
  padding: 0 0px;
  border: 1px solid #f5f5f5;
  background-color: #ffffff;
  position: relative;
}

.admin-wrapper .admin-history-container {
  height: 100%;
  overflow-y: scroll;
}

.admin-wrapper .admin-history-container table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.admin-history-container table thead {
  position: sticky;
  z-index: 1;
  inset-block-start: 0;
  width: 100%;
  background-color: var(--white);
  box-shadow: 0 1px 1px #f5f5f5;
}

.admin-history-container table thead tr td {
  text-align: left;
  padding: 5px 10px 5px 10px;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
}

.admin-history-container table tbody {
  width: 100%;
}

.admin-history-container table tbody tr {
  cursor: initial;
  transition: 0.3s;
  border-bottom: 1px solid #f5f5f5c5;
}

.admin-history-container table tbody tr:hover {
  background-color: #fcfcfc;
}

.admin-history-container table tbody tr td {
  padding: 14px 10px;
  color: var(--font-primary);
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.5px;
}

@media only screen and (max-width: 768px) {
  .order-container-empty>img {
    width: 80px;
  }

  .order-container-empty>div>p {
    font-size: 16px !important;
  }

  .order-container-empty>div>span {
    font-size: 13px;
  }

  .admin-wrapper {
    height: 100%;
    padding: 0 10px;
  }

  .admin-wrapper .admin-menu .admin-menu-option {
    margin-right: 10px;
    padding: 10px 30px;
  }

  .admin-menu .admin-menu-option svg {
    width: 16px;
    height: 16px;
  }

  .admin-menu .admin-menu-option span {
    font-size: 14px;
    font-weight: 600;
  }

  .admin-wrapper .table-container,
  .admin-wrapper .table-loader {
    background-color: var(--white);
    border-radius: 4px;
    margin-top: 20px;
    width: 100%;
    height: 90%;
    padding: 0px 0px 5px;
    overflow-y: scroll;
  }

  .admin-wrapper .table-loader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .admin-wrapper .table-loader>p {
    font-size: 14px;
    margin-top: 10px !important;
  }

  .admin-wrapper .order-trades-container {
    box-shadow: 0 0 2px #e8e8e8;
    overflow-x: scroll;
  }

  .admin-wrapper .order-trades-container table {
    min-width: 800px;
    width: 100%;
    border-collapse: separate;
  }

  .order-trades-container table thead tr {
    z-index: 1000;
  }

  .order-trades-container table thead tr th {
    padding: 6px 0 6px 5px;
    min-width: 4.51vw;
    font-size: 12px;
    z-index: 2;
    background-color: var(--white);
  }

  .order-trades-container table thead tr th:nth-child(1) {
    position: sticky;
    left: 0;
    z-index: 3;
  }

  .order-trades-container table tbody {
    width: 100%;
  }

  .order-trades-container table tbody tr td:nth-child(1) {
    position: sticky;
    left: 0;
    background-color: var(--white);
    max-width: 6.45vw;
    z-index: 1;
  }

  .order-trades-container table tbody tr td {
    padding: 12px 0 12px 5px;
    font-size: 10px;
  }

  .order-trades-container table tbody tr td .stock-info .stock-name {
    font-weight: 700;
  }

  .order-trades-container table tbody tr td .stock-info .stock-fullname {
    font-size: 9px;
    max-width: 80px;
  }

  .order-trades-container table tbody tr td:nth-child(9) {
    min-width: 7.74vw;
  }

  .order-trades-container table tbody tr td .modify-order,
  .order-trades-container table tbody tr td .cancel-order {
    width: 24px;
    height: 24px;
  }

  .order-trades-container table tbody tr td .modify-order>img,
  .order-trades-container table tbody tr td .cancel-order img {
    width: 14px;
  }

  .order-info {
    border-radius: 4px;
    margin-top: 20px;
    width: 100%;
    height: 90%;
    padding: 0px 0px 5px;
    background-color: #fdfdfd;
  }
}


.admin-customer-container table tbody tr td,
.admin-broker-container table tbody tr td,
.admin-superuser-container table tbody tr td,
.admin-stock-container table tbody tr td,
.admin-activation-container table tbody tr td,
.admin-orders-mf-container table tbody tr td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.admin-customer-container table tr td.name,.admin-activation-container table tr td.name{
  width: 220px;
}

.admin-customer-container table tr td.email,.admin-customer-container table tr td.registration-time,.admin-activation-container table tr td.email,.admin-activation-container table tr td.registration-time,.admin-activation-container table tr td.activation-time{
  width: 180px;
}

.admin-customer-container table tr td.pan-image,.admin-customer-container table tr td.status{
  width: 120px;
}

.admin-customer-container table tr td.role{
  width: 140px;
}


.admin-customer-container table tr td.edit,.admin-broker-container table tr td.edit,.admin-superuser-container table tr td.edit,.admin-stock-container table tr td.edit,.admin-dealer-container table tr td.edit{
  width: 60px;
}

.admin-customer-container table tr td.deactivate,.admin-broker-container table tr td.deactivate,.admin-superuser-container table tr td.deactivate,.admin-activation-container table tr td.deactivate{
  width: 120px;
}

.admin-broker-container table tr td.contact{
  width: 240px;
}

.admin-superuser-container table tr td.arrow{
  width: 80px;
}

.admin-stock-container table tr td.company{
  width: 400px;
}

.admin-stock-container table tr td.series{
  width: 100px;
}

.admin-stock-container table tr td.isin{
  width: 180px;
}

.admin-stock-container table tr td.delete{
  width: 100px;
}

.admin-activation-container table tr td.status,.admin-activation-container table tr td.vertical{
  width: 80px;
}

.admin-activation-container table tr td.view{
  width: 80px;
}


.admin-table-wrapper{
  width: 100%;
  height: 95%;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.admin-table-wrapper .admin-table-data{
  width: 100%;
  min-height: 90%;
  max-height: 90%;
  overflow-y: auto;
}

.admin-table-wrapper .admin-table-data td.scheme-name{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.admin-table-wrapper .admin-table-footer{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.admin-table-wrapper .admin-table-footer .footer-buttons{
  display: flex;
  column-gap: 10px;
}

.admin-table-footer .footer-buttons button{
  padding: 6px 24px;
  background-color: #f5f5f5;
  border: 1px solid #e8e8e8;
  color: var(--font-primary);
  border-radius: 5px;
  cursor: pointer;
}